import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "13 Days Rwenzori Trekking & Gorillas | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/22.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>13 Days Rwenzori Trekking & Gorillas</h2>
              <h4 className='quote-h4'>Let’s find some beautiful place to get lost</h4>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Route I- From Kampala or Entebbe  – Fort Portal.</span></strong> In the morning at 0830 hrs pick up from your hotel in Kampala and drive to Fort Portal. Lunch will be en-route and on arrival, you check in at Mountains of the moon Hotel for dinner and overnight. 
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/93.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Route 2 - From Fort Portal – Nyabitaba hut 2650m.</span></strong> Wake up at 0550hrs and drive to Nyakalengija 1615m then you will start trekking thru coffee plantation to cross the Mahoma River. Proceed thru forest where after 5hrs you will arrive at Nyabitaba hut for dinner and overnight. 
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/37.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Route 3 - From Nyabitaba – John Matte hut 3550m.</span></strong> After breakfast, descend thru forest to cross the Bajuku River at the Kurt Schafer Bridge and thru giant heather and grounded forest, lunch on the way after 7 hrs you will arrive John Matte hot for dinner and overnight. 
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/38.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 4: Route 4 - From John Matte – Lake Bujuku hut 3900m.</span></strong> After breakfast, leave Matte hut and proceed thru the two Bigo Bogs, to Bigo hut crossing the Kibatsi Bog to cooking port cave and on to the beautifully set lake Bajuku hut. Fine views of Mountains Stanley incredible ice cave in Mt. Speke and Baker. Dinner and overnight at Bujuku hut.  
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/39.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>

    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 5: Route 5 - From Bujuku hut – Elana hut – Summit (4540m) – Kitandara hut 3910.</span></strong> Breakfast followed by climb to the Elana hut, proceed for your final ascend of Margherita, the summit of the highest mountain range in Africa. Later descend to Kitandara hut. 
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/33.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 6:  Rest  & Relax.</span></strong> This day you will rest and relax exploring the beautiful of Kitandara lakes. You will have all meals and night at Kitandara hut. 
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/13.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 7: Route 7 - From Kitandara Hut – Guy Yeoman hut 3450m.</span></strong> After breakfast, make the steep ascend to the fresh field pass 4282m and then descend to Bujongolo cave 3720m (the base used for Duke of Abuzzi? 1906 expedition). Proceed thru Kabamba cave 3450m, the waterfall and the rock shelter for dinner and overnight at the Guy Yeoman hut set in the giant heather zone. 5hrs.
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/45.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 8: Route 8 - From Guy Yeoman hut – Nyakalengija 1615m – Fort Portal.</span></strong> Breakfast followed by final descend 7hrs steeply thru the bamboo zone via lake Mahoma to meet the road where you will be picked for your return to the hotel Fort Portal. 
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/52.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 9: Route 9 -From Fort Portal – Kabale.</span></strong> After breakfast, then drive along one side of the Mountain series to Kabale, you will have lunch on the way, dinner and overnight at Bunyonyi Safaris Resort.
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/51.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 10: Route 10 – From Kabale – Bwindi Impenetrable Park.</span></strong> After Breakfast, at 0830hrs take a short drive from Kabale to Bwindi impenetrable Forest National Park. You will arrive to the gorilla reserve in the late morning, the rest of the day relax at leisure in the camp waiting for dinner and overnight Buhoma community camp. 
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/56.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 11: Route 11 - Gorilla viewing.</span></strong> Breakfast in the camp followed by walking in thru the device forest and meet a whole family of Gorillas. Your guide will advise the time to spend watching gorillas, time for your picnic lunch and time to return to your camp for Dinner and overnight. 
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/55.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 12: Route – Wrap up in the Forest.</span></strong> Breakfast followed by a day walk through the forest and the local villages, picnic lunch, dinner and overnight in your camp. 
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/61.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>

    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>    
            <div className='home-info'>               
<p className='home-p'><strong><span className='safari-title'>Day 13:  Route 13 - Back To Kampala.</span></strong> After breakfast, drive back to Kampala with lunch in Mbarara.  
</p>
      </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/66.jpg' className='bubu-logo'></img>
           </div>
        </div>
    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home