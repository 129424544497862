import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Tips To Note | Green Origin Tours - Africa Ltd";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/17.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Tips To Note</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          <h2 className="home-h2 tips-h2">First Time To Africa?</h2>
   

                
<p className='home-p'>
 <strong> Safari Experience! Things to Note</strong><br></br>
  The reason for going on a safari is to experience the bush and the animals. Some safari-goers find some animals less enjoyable, however, and even the most popular species may turn scary or even dangerous if met under the wrong circumstances. Wild animals Some African mammals may become aggressive when faced, for example elephants, buffalos, hippopotamus, lions and leopards. Incidents involving these (or other) mammals do happen, but are very rare and can mostly be avoided by behaving wisely. When inside your safari vehicle, you’re safe. When on foot, the basic rules are staying away from areas if you aren’t sure that there are no animals, and staying away from animals that you do see. Any animal of some size may get aggressive if threatened, or if young ones or food are threatened. An animal may behave non-aggressive even though it knows you are there. If you are far enough away, it doesn’t feel threatened by you. But if you move closer, this may change.
</p>

<p className='home-p'>
  <strong> Animals in lodges</strong><br></br>
On most safaris in East Africa, you watch the animals from a jeep or minibus, which is safe. You may meet animals, mainly such as monkeys and mongoose, on foot in lodges or tented camps. These animals are rarely dangerous, but they are wild, so keep your distance to them. Even if they behave as were they tame, they may use teeth and claws if feeling threatened. Also keep unattended doors and windows to your room closed, to prevent monkeys and ground squirrels from entering searching for food. Never venture outside the lodge or camp area. If you move within the lodge area at night, for example to see if you can spot some nocturnal animals, you may ask a watchman to escort you (tip him afterwards). Nights are less safe for you, even within the lodge (unless the lodge is fenced). Wild animals from the surrounding bush may enter the area at night, when the lodge is quiet. We have seen elephants, buffalos, hippos, lions and leopards at night in lodges and camps.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong> Don’t run from predators</strong><br></br>
If you meet a large predator while on foot, don’t run. Running may trigger the predator to give chase, and is also quite pointless, as the animal runs twice as fast as you. If there are two of you (which there should be, especially at night) or more, move together closely; the predator may then see you as one big opponent, instead of a couple of small ones. In lodge and camp areas, a predator appearing is usually just passing through. Let it pass. Slowly back off. Then inform a watchman or other staff that there are predators around. Tell others to stay away. A predator closing in on you should be told that you don’t like it. Shout at it. Be dangerous. Pelt it with stones if it comes too close.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong> Stay in the vehicle</strong><br></br>
Don’t get out of the vehicle unless the driver says it is ok. In many parks, moving on foot is not only risky but also means breaking the park rules. There are special places, such as observation points and picnic sites, where it is allowed to get out.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong>Walking safaris</strong><br></br>
Meeting animals on foot is part of the walking safari concept. Most animals move away when they become aware of you, which usually happens at some distance (you generally get closer to animals if you approach them by car). Walking safaris in wildlife areas should always be escorted by an armed ranger. If on foot close to lakes or rivers where crocodiles may be found, you should stay at least 5 m/yd. away from the water’s edge. Crocs have good camouflage, and may be hiding in the water to ambush prey approaching on land. Don’t leave children unattended.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong>Dead animals</strong><br></br>
Your safari driver will probably do his best to make you see animals hunt, kill or eat each other. If he doesn’t succeed, he will probably at least find you some carcass to look at. This means, there may be unpleasant scenes awaiting you. If you don’t want to see such, you can look the other direction.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong>Snakes</strong><br></br>
It is rare seeing snakes on safaris. Most snakes try to get out of your way when they notice you, so they are gone before you have a chance seeing them. But all don’t, and as some East African species have strong or even deadly venoms, you should always look where you are walking and use a flashlight when walking outdoors at night. Snakes don’t bite because they are evil, but because they are frightened or feel threatened. For safety reasons, you should stay at least two snake lengths away from any snake you can’t identify as harmless. For the reason of not disturbing the animal, you should back away even further. Never try to handle a snake unless you know what you are doing. Seemingly dead snakes may not be dead at all, and should not be approached. And so on. In short, stay away from snakes. The only snakes that may regard humans as prey are very large pythons, but they are not seen very often. Don’t leave children unattended where there are pythons around.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong>Crocodiles</strong><br></br>
Nile crocodiles in Africa regularly kill humans. They are mostly found in fresh water, but may occur in brackish water and even enter the sea. Be aware when approaching waters where crocodiles are known to live. Look where you are going, and stay 10 metres/yards or more from lake and river shores.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong>Other reptiles and amphibians</strong><br></br>
There are no poisonous lizards or frogs in East Africa. The largest lizard, the Nile monitor, is shy but is capable of biting if cornered. Wash your hands after handling reptiles or amphibians (but rather leave them alone).
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>

<p className='home-p'>
  <strong>Insects and creeping things</strong><br></br>
You don’t see that many insects or creeping things during dry seasons. More appear during rainy seasons (including beautiful ones, such as butterflies). The same goes for mosquitoes, which by biting may infect you with malaria, a life-threatening disease unless properly treated. Mosquitoes thrive in moist and warm areas, and are most common during rainy seasons, near rivers and lakes, and by the coast. The mosquitoes that may carry malaria are active at night. Tsetse flies, which are active during daytime, may infect you with sleeping sickness. This is very rare to safari-goers, though. The bites hurt a lot, and are reason enough to kill or chase flies out of the vehicle. Avoid storing food in your room or tent, as it may attract ants.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>



           </div>
           {/* <div className='bubu-logo-cover'>
                     <img src='./images/19.jpg' className='bubu-logo'></img>
           </div> */}
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home