import HomeVideo1 from '../components/video/video1.mp4'
import {Link} from 'react-router-dom';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}
const HomeVideo = () => {
  return (
    <div  className='home-video'>
      <div className='overlay'></div>
         <video src={HomeVideo1} autoPlay loop muted className='home-video1'></video>
       <div className='hero-text'> 
        <h2>Explore</h2>
        <div className='line-a'></div>
        <h1 className="home-h1">East Africa With Us</h1>
        <div className='line-a'></div>
        <p className='home-hero-p'>We offer safaris to all East African countries. Let us plan your holiday now!</p>
        <div className='btns-cover'>
             <Link to='who-we-are' className='tours-link' onClick={scrollFunc}>About Us</Link>
             <Link to='services' className='tours-link tours-link-a' onClick={scrollFunc}>Our Services</Link>
        </div>
        </div>
        

    </div>
  )
}
export default HomeVideo