import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';
import HomeVideo from '../components/HomeVideo';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Our Services | Salimo Safaris Uganda";

},[]);

  return (
    <>
   
   <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 furniture-h2">Our Services</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
{/* Cards Start */}
            <div className='services-container-new'>
          <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
              
              <div className='tools-slides-cover'>
                 <img src='./img/1.jpg' class='safari-image'></img>
                {/* <Tool8></Tool8> */}
              </div>
           <h3 className='skill-h3 service-h3-new service-h3-new-a'>Gorilla Tour Packages</h3>
            {/* <p className='skill-p'>We have knowledgeable and experienced tour guides. Alternative languages can be offered on request depending on the travel time and availability of our guides. All our guides have versatile knowledge about the destinations, parks, activities, culture and wildlife.</p> */}
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
            <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./images/56.jpg' class='safari-image'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Chimpanzee Tracking</h3>
            {/* <p className='skill-p'>There are numerous camp grounds all over East Africa catering for all levels of adventure. Some campsites are private while others operated by wildlife authorities within the wildlife reserves and national parks. The campsites vary in facilities and amenities. </p> */}
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
           <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./img/3.jpg' class='safari-image'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Wildlife Safaris</h3>
            {/* <p className='skill-p'>East Africa is a unique destination to those who enjoy hiking and mountain climbers given its beautiful weather and physical features that support the activity. Our mountaineering service enables travelers explore different attractions situated in mountainous regions.</p> */}
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
           <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./img/122.jpg' class='safari-image'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Visa & Ticket Booking</h3>
            {/* <p className='skill-p'>Community tourism refers to tourism services and experiences that are part of the community daily life in the local setting, presented to the tourists by the community members themselves. Our community tourism products and experiences include community accommodation, community tours, demonstrations, handicrafts, food and beverages.</p> */}
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
          <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./img/2.jpg' class='safari-image'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Car Hire Services</h3>
            {/* <p className='skill-p'>Cultural Tourism is greatly enjoyed while on our safaris. East Africa is blessed with abundant wildlife, unique features and a fascinating cultural heritage. East Africans are accepting and easily get along with foreigners. East Africa is a region with a strong cultural heritage and this is demonstrated by the numerous cultural sites and tribes in the region.</p> */}
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
           <article className='single-service-new'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
             
              <div className='tools-slides-cover'>
                 <img src='./img/121.jpeg' class='safari-image'></img>
                {/* <Tool8></Tool8> */}
              </div>
            <h3 className='skill-h3 service-h3-new service-h3-new-a'>Air Ticketing</h3>
            {/* <p className='skill-p'>Cultural Tourism is greatly enjoyed while on our safaris. East Africa is blessed with abundant wildlife, unique features and a fascinating cultural heritage. East Africans are accepting and easily get along with foreigners. East Africa is a region with a strong cultural heritage and this is demonstrated by the numerous cultural sites and tribes in the region.</p> */}
            {/* <Link to='#' className='service-btn' onClick={scrollFunc}>See Details<span>
              <FaArrowRight></FaArrowRight>
              </span></Link> */}
          </article>
       
            
      </div>

      {/* Cards End */}

   

      {/* Modals End */}

         </div>
    </section>
  
  
    </>
  )
}

export default Home