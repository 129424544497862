import React from 'react'

let ValuesData = [

{title: "What is special in Africa?",      
content: "The African continent has a unique place in human history-widely believed to be the “Cradle of Humankind”. Africa is the only continent with fossil evidence of human beings (homo-sapiens) and their ancestors through each key stage of their evolution."
},

{title: "Why do Tourist come to East Africa? ",      
content: "East Africa provides once in a life time opportunity to see and experience untouched terrain and culture. You will be able to see wildlife, nature and landscapes in ways you have never seen before."
},
{title: "Why Uganda in specific? ",      
content: "Uganda is the pearl of Africa, your perfect destination, source of the great River Nile and home to the planets most powerful water falls-the Murchison, the all year around sunshine, (loosely referred to the sunshine country), savannah grasslands, and mountain climbing to the Rwenzori snow copped mountains of the moon, home to the endangered Gorilla species, chimpanzee parks, big game parks and birding paradise. Wait, how about the Africa’s longest fresh water lake(Lake Victoria), its beautiful people and more."
}
];

export default ValuesData;