import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "3 Days Maasai Mara Wildlife Safari | Salimo Safaris Uganda";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./img/97.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>3 Days Maasai Mara Wildlife Safari</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
     
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Drive from Nairobi to Maasai Mara.</span></strong> You will be met at your Nairobi hotel at 7.00 am by your safari driver guide. After a short tour briefing, you will be driven west for about 6 hours past impressive views of the Great Rift Valley, Lake Naivasha, and Mount Longonot to one of the most famous wilderness areas in Africa, Masai Mara National Reserve.

It’s common to see lions either basking after a heavy meal or surveying the plains for their next meal. The ultimate action here, however, is the annual Great Migration which happens from around July to October every year. About 2.5 million wildebeest, zebra, and gazelle move through the Serengeti and Maasai Mara in search of greener pastures. If lucky enough to witness it, it will be a lasting memory. You will arrive in the afternoon, check in, enjoy lunch, relax and then depart on an exciting drive. You will stay out until sunset and hopefully you can find some predators as they start to become more active at this time.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./img/93.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Enjoy An Extensive Game Drive in Masai Mara Game Reserve.</span></strong> You’ll have a full-day game drive with a bush picnic lunch near the great Mara River as you enjoy the scenic view of the enormous Nile crocodiles resting on the brown-muddy river banks. The rolling grasslands of the Mara offer ideal game viewing and photographic opportunities and the grassy plains are broken by rocky outcrops which are favorite midday resting places for Lion.

In the evening, visit a traditional Maasai village to be enlightened on the Masai culture and perhaps enjoy a Masai cultural dance. The Maasai people are the ancestral inhabitants of the area and the Masai Mara Game Reserve is named in their honor, and their description of the area when looked at from afar. “Mara,” which is Maa (Maasai language) for “spotted,” is an appropriate description for the circles of trees, scrub, savanna, and cloud shadows that mark the area.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./img/114.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Do a Morning Game Drive in Masai Mara National Reserve.</span></strong> On the final day of your 3 days Masai Mara safari in Kenya tour, you’ll set off with your bags on your last morning safari at sunrise through the Masai Mara National Reserve with a packed picnic breakfast on board. You can explore the Paradise Plains and the Musiara Mars which offer terrific game viewing and are well-known for Disney’s African Cats series and BBC’s popular Big Cat Diaries.

Thereafter, you drive back to Nairobi at the end of this fantastic 3 days Masai Mara safari. You can opt to fly home today or you could fly to Mombasa for beach relaxation. You may fly to Tanzania for a more dramatic Africa wildlife safari or Uganda/Rwanda for mountain gorilla trekking tracking tours if you are interested.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./img/117.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home