import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "3 Days Rwanda Gorilla Safari In Volcanoes | Salimo Safaris Uganda";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/30.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>3 Days Rwanda Gorilla Safari In Volcanoes</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Pick up, city tour & transfer to Volcanoes national park.</span></strong> Volcanoes national park is 104 Km away from Kigali. Because of the speed limits imposed by the Rwandan government, it takes approximately 3 hours to connect to the park by road. On arrival at the airport, our driver guide will take care of all your logistics and onward transfers. Depending on your itinerary previously organised for you, the guide may take you for a city tour in Kigali include Gisozi genocide memorial site, local art, and craft market. After lunch you may transfer to Kinigi in the Parc National des Volcans. Dinner and overnight at Tiloreza Volcanoes Eco Lodge, Mountain gorilla view lodge/Gorilla solution lodge/ Da Vinci gorilla lodge.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/53.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Gorilla Trekking</span></strong> Have early breakfast, and head onto the park headquarters for briefing from the park guides. Make sure to carry with you packed lunch and drinking water since you may spend some good hours in search of the habituated Nyakagezi gorilla group since their movement is unpredicted rangers do track their movements all thus failing to find them is minimal. When you find them you are allowed not more than an hour around them. After trekking, return to the lodge to relax, have dinner and then overnight. As early as 6:30 am enjoy your breakfast and then transfer to the national park headquarters for a briefing from the senior park warden in preparation for the day’s gorilla trekking. At 8:00 am, your tour guide will lead you to the starting point of the hike. In the company of armed game rangers and porters follow the existing trail until you encounter the gorilla group pre-booked for you. Normally it takes 1 to 3 hours to encounter the gorillas depending on their movements the previous night. You are allowed to stay in the presence of the gorillas for at least an hour. Later return to your lodge for your lunch and afternoon programs.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/54.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Cultural & Community visit.</span></strong> After breakfast undertake a community walk to the Iby’iwachu Cultural Village for a traditional Rwanda cultural performance. The traditional dances, poems, and dressing codes are quite memorable. Return to the lodge for a hot lunch and then transfer to Kigali for a scheduled return flight.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/55.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home