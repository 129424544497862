import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "1 Day Source of the Nile River Safari | Salimo Safaris Uganda";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/19.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>1 Day Source of the Nile River Safari</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Kampala - Jinja.</span></strong> Start off the journey not later than 7am on a 2 hours road drive, the early morning drive is not to encounter with the traffic jam from your area of pick up up to the Jinja basins, the high ways from the capital City to Jinja are more used by the natives and tourists per day
</p>

<p className='home-p'>
It is in Jinja that the first and biggest hydro-electric power station was established, creating an enormous chance to new rising industries being established thus rewarding Jinja, the second Largest economic activity in Uganda the pearl of Africa. Jinja is a tourist hub of its own, making it different from other cities / places because of its unique location near two big fresh water bodies of Lake Victoria and the Nile River. It is in Jinja that you will find unique features like the Source of the Nile River, among others, the Sezibwa falls, the big natural forest of Mabira and many more.
                 {/* So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality. */}

</p>



           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/19.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home