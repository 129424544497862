import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "3 Days Akagera National Park Safari | Salimo Safaris Uganda";

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(69, 68, 13, 0.6),rgba(69, 68, 13, 0.6)), url("./images/31.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'12rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>3 Days Akagera National Park Safari</h2>
            {/* <div className='pledge-line'></div> */}
        
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 1: Drive from Kigali City to Akagera National Park.</span></strong> Your tour guide/driver shall pick you from your hotel/residence in Kigali at 7.00am and depart for Akagera National Park in the east of Rwanda. The distance from Kigali to entrance gate of Akagera National Park is about 110km and the travel time is about two and half hours. Upon arriving in Akagera you shall immediately go on a game drive for your first wildlife sightings and enjoy the beautiful scenery. Later you shall continue to your lodge and have lunch. At 2.30pm you shall go for a one hour boat trip on Lake Ihema. This is the biggest lake in the park. The boat trip offers wonderful views of hippos and crocodiles and other wildlife along the lake shores and the islands.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/52.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
     <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 2: Morning safari in Akagera and afternoon at leisure</span></strong> Today you are set to do a morning game drive in Akagera National Park. You shall cover more game trails and reach more areas of the park to see more animals and enjoy the beautiful landscape. Later you shall return to the lodge for lunch and thereafter spend the rest of the day at leisure.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/56.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b content-container-safaris'>
           
          
            <div className='home-info'>
          {/* <h2 className="home-h2">Who We Are</h2> */}
   

                
<p className='home-p'><strong><span className='safari-title'>Day 3: Full safari in Akagera and exit the park.</span></strong> You will have breakfast as early as 6am and at about 6.30am check out and set off for the full safari in Akagera National Park. This is a 6 hour game drive from the lodge/entrance in the south to the exit in the north of the park. You will cover a variety of habitats from woodland, wetlands, bushes and thickets, forest and grassland, which will provide a variety of wildlife, and finally get to the Kilala plains in the north. Kilala plains are beautiful flat savanna plains where a lot of animals graze such as antelopes, buffaloes, and zebras. The variety of grazing animals in the Kilala plains makes it a favorite hunting ground for the predators, and it is therefore the place to look for the lions, hyenas and other predators. These plains are the main animal watching area of the park one cannot afford to miss. After fully exploring the Kilala plains you shall have picnic lunch and then exit the park and head back to Kigali.
</p>

           </div>
           <div className='bubu-logo-cover'>
                     <img src='./images/57.jpg' className='bubu-logo'></img>
           </div>
        </div>

    </section>

  
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home